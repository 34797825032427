<template>
  <v-list-item @click="dialog = true" two-line>
    <drug-result-dialog @close="dialog = false" :active="dialog" :drug="drug" />
    <v-list-item-content>
      <v-list-item-title class="font-weight-medium">
        {{ drugName }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="drug.drugResult.result >= 0">
        {{ $t(`drug_result_${recommendationIndex}`) }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-else>
        {{ $t(`sin_interacciones`) }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <ColorScore :score="drug.drugResult.result" />
  </v-list-item>
</template>

<script>
import DrugResultDialog from "@/components/mylogy/pgx_results/DrugResultDialog.vue";
import ColorScore from "@/components/mylogy/ColorScore.vue";

export default {
  name: "SearchbaseDrugResultItem",
  components: { DrugResultDialog, ColorScore },
  props: ["drug"],
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    drugName() {
      return this.drug.source[`Drug_Name_${this.$i18n.locale.toUpperCase()}`];
    },
    recommendationIndex() {
      if (this.drug.drugResult.result >= 0.75) {
        return "2";
      } else if (this.drug.drugResult.result >= 0.5) {
        return "1";
      } else if (this.drug.drugResult.result >= 0.25) {
        return "0";
      }
      return "3";
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card .v-card__actions {
  background: #ffffff00;
  transition: 0.3s;
  box-shadow: 0 5px 40px -30px inset #310ab1;
}
</style>
