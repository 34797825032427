<template>
  <v-list-item @click="dialog = true">
    <v-icon :color="gene.geneResult.label" large class="mr-2"
      >mdi-circle</v-icon
    >
    <gene-result-dialog
      @close="dialog = false"
      :active="dialog"
      :geneData="geneData"
    />

    <v-list-item-content>
      <v-list-item-title>
        <span class="font-weight-medium">{{ gene.source.Symbol }}</span> -
        {{ pgxGene(gene.source.Symbol).name[$i18n.locale] }}
      </v-list-item-title>
      <v-list-item-subtitle class="">
        ({{ gene.geneResult.result }})
        {{
          pgxGene(gene.source.Symbol).texts[gene.geneResult.label][$i18n.locale]
            .phenotype
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import GeneResultMixin from "@/mixins/GeneResultMixin.vue";
import GeneResultDialog from "@/components/mylogy/pgx_results/GeneResultDialog.vue";
export default {
  name: "SearchbaseGeneResultItem",
  props: ["gene"],
  components: {
    GeneResultDialog
  },
  mixins: [GeneResultMixin],
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapGetters("pgxGenes", ["pgxGene"]),
    geneData() {
      return {
        ...{ gene: this.pgxGene(this.gene.source.Symbol) },
        ...{ result: this.gene.geneResult },
        ...{ geneId: this.gene.source._id }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.left-border {
  border-left: solid 2px #4d2cb9;
  display: flex;
}
.v-card .v-card__actions {
  background: #ffffff00;
  transition: 0.3s;
  box-shadow: 0 5px 30px -20px inset #310ab1;
}
</style>
