<template>
  <v-container id="explorer" fluid tag="section" class="pt-0">
    <fondo-custom></fondo-custom>

    <!-- TITULO -->
    <v-row 
      justify="center"
      class="sticky background-gradient top-title z-index-99"
    >
      <page-top-title>
        <template v-slot:toptitle-icon>mdi-pill</template>
        <template v-slot:toptitle-text>{{ $t("farmacogenetica") }}</template>
      </page-top-title>
    </v-row>

    <reactive-base
      :app="app"
      :url="url"
      :key="searchBaseKey"
      :credentials="credentials"
      :type="type"
    >
      <!-- RESULTADOS -->
      <v-row justify="center">
        <v-col cols="12" md="7">
          <v-card class="pa-0" rounded="xl">
            <v-card-title
              class="solid-heading rounded-t-lg py-0 px-3 px-sm-8 mb-0"
            >
              <data-search
                :innerClass="{ input: 'reactive-search-input' }"
                :showIcon="false"
                :showClear="false"
                :URLParams="true"
                :placeholder="searchPlaceholder"
                :filterLabel="$t('nombre')"
                componentId="SearchSensor"
                :autosuggest="true"
                :dataField="datafields"
                class="grow"
              />
            </v-card-title>

            <v-card-text class="px-0">
              <v-row class="px-4 px-sm-8 search-bar mt-0 mx-0 active-filters py-4" justify-content="space-between" align="center">
                <like-radio-buttons
                  @buttonSelected="buttonSelected"
                  :activeButtonKey="app"
                  :buttons="[
                    {
                      text: $t('Medicamentos'),
                      icon: 'pill',
                      key: 'kb-drugs'
                    },
                    { text: $t('Genes'), icon: 'dna', key: 'kb-genes' }
                  ]"
                  class="buttons d-flex flex-wrap"
                />
                <v-switch
                  v-model="includeWithoutPGX"
                  :label="$t('include_without_interaction')"
                  inset
                  class="notdefined-switch my-1"
                ></v-switch>
              </v-row>

              <v-row class="px-0 px-sm-4 mx-0">
                <v-list class="searchbase-results" rounded>
                  <v-list-item-group color="primary">
                    <ReactiveList
                      componentId="result"
                      dataField="Critic_Score"
                      class="results"
                      :prevLabel="$t('Prev')"
                      :nextLabel="$t('Next')"
                      :sortOptions="sortOptions"
                      :innerClass="{ resultStats: 'result-stat' }"
                      :defaultQuery="defaultQuery"
                      pagination
                      :pages="4"
                      :size="8"
                      :showResultStats="false"
                      :react="reactiveListReact"
                    >
                      <div slot="renderItem" slot-scope="{ item }">
                        <searchbase-drug-result-item
                          v-if="app == 'kb-drugs'"
                          :drug="drug(item)"
                        />
                        <searchbase-gene-result-item
                          v-else
                          :gene="gene(item)"
                        />
                      </div>
                    </ReactiveList>
                  </v-list-item-group>
                </v-list>
              </v-row>
            </v-card-text>
            <v-card-actions v-if="app == 'kb-drugs'" style="display: block;">
              <v-row class="px-4">
                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header hide-actions>
                      <v-col cols="6" sm="9" class="px-0"
                        ><v-divider></v-divider
                      ></v-col>
                      <v-col cols="6" sm="2" class="px-0"
                        ><v-btn
                          rounded
                          outlined
                          block
                          class="text-align-right"
                          color="primary"
                          >{{ $t("leyenda") }}
                          <v-icon>mdi-chevron-down</v-icon></v-btn
                        ></v-col
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="px-4">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <div class="d-flex my-2">
                            <v-badge color="grey" inline left>
                              {{ $t("no_analizado") }}
                            </v-badge>
                          </div>
                          <div class="d-flex my-2">
                            <v-badge color="green" inline left>
                              {{ $t("respuesta_normal") }}
                            </v-badge>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <div class="d-flex my-2">
                            <v-badge color="yellow" inline left>
                              {{ $t("respuesta_anomala_toxicidad_moderada") }}
                            </v-badge>
                          </div>
                          <div class="d-flex my-2">
                            <v-badge color="orange" inline left>
                              {{
                                $t(
                                  "respuesta_anomala_riesgo_de_ineficacia_terapeutica"
                                )
                              }}
                            </v-badge>
                          </div>
                          <div class="d-flex my-2">
                            <v-badge color="red" inline left>
                              {{
                                $t(
                                  "respuesta_anomala_riesgo_de_toxicidad_elevado"
                                )
                              }}
                            </v-badge>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="legend-disclaimer">
                          {{ $t("pgx_disclaimer") }}
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- FILTROS -->
        <v-col cols="12" md="4">
          <v-card class="pa-0" rounded="lg">
            <v-card-title class="solid-heading rounded-lg mb-4 py-3">
              <h3 class="white--text font-weight-regular">
                <v-icon color="white">mdi-filter</v-icon>{{ $t("filters") }}
              </h3>
            </v-card-title>
            <v-card-text >
              <selected-filters id="selected-filters">
                <div slot-scope="{ selectedValues, setValue, clearValues }">
                  <div v-for="componentId in Object.keys( getFilteredValues(selectedValues) )" :key="componentId" class="px-4">
                    <div> {{ selectedValues[componentId].showValue || selectedValues[componentId].value }}
                      <v-btn  @click="clearFilter(componentId, setValue)" icon color="red">
                        <v-icon class="mt-n2">mdi-minus-circle</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div class="mt-2">
                    <v-btn rounded v-if="Object.keys(getFilteredValues(selectedValues)).length " @click="clearValues" block color="red" class=" mb-8">
                      {{ $t("reset_filters") }}
                      <v-icon class="mt-n1 ml-4">mdi-autorenew</v-icon>
                    </v-btn>
                  </div>
                </div>
              </selected-filters>
              <div v-if="app == 'kb-drugs'" class="drug-filters">
                <single-dropdown-list
                  :innerClass="{ input: 'reactive-search-input' }"
                  :showIcon="false"
                  :URLParams="true"
                  :title="$t('Por enfermedades')"
                  :filterLabel="$t('enfermedades')"
                  :showClear="true"
                  :size="100000"
                  placeholder=""
                  componentId="DrugSearchDisesasesSensor"
                  :autosuggest="true"
                  :showSearch="true"
                  :showCount="false"
                  sortBy="asc"
                  :dataField="
                    `DC_Ind_${this.$i18n.locale.toUpperCase()}.keyword`
                  "
                  class="mb-4 grow z-index"
                />

                <data-search
                  :innerClass="{ input: 'reactive-search-input' }"
                  :showIcon="false"
                  :URLParams="true"
                  :title="$t('Genes relacionados')"
                  :filterLabel="$t('genes')"
                  :showClear="false"
                  placeholder=""
                  componentId="DrugSearchGenesSensor"
                  :autosuggest="true"
                  :dataField="drugGeneDatafields"
                  class="mb-4 grow z-index"
                />

                <h3 class="title font-weight-semibold text-h4 mt-12">
                  {{ $t("Por categorias") }}
                </h3>
                <data-search
                  style="display: none;"
                  componentId="CategorySensor"
                  :URLParams="false"
                  :customQuery="catCustomQuery"
                  :dataField="['EPG_drug_categories_string']"
                  :autosuggest="false"
                  :highlight="false"
                  :filterLabel="$t('category')"
                  :value="categoryValue"
                  :showFilter="true"
                />
                <DrugCategoriesFilter
                  @selected-category="selectDrugCategory"
                  :activeCategory="categoryValueOriginal"
                />
              </div>

              <div v-else class="genes-filter">
                <single-dropdown-list
                  :innerClass="{ input: 'reactive-search-input' }"
                  :showIcon="false"
                  :URLParams="true"
                  :title="$t('Por enfermedades')"
                  :filterLabel="$t('enfermedades')"
                  :showClear="true"
                  :size="100000"
                  placeholder=""
                  :showCount="false"
                  sortBy="asc"
                  componentId="GeneSearchDisesasesSensor"
                  :autosuggest="true"
                  :showSearch="true"
                  :showFilter="true"
                  :dataField="`Dis_${$i18n.locale.toUpperCase()}.keyword`"
                  class="mb-4 grow z-index"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- resultados -->
    </reactive-base>
  </v-container>
</template>

<script>
import { CRED, TYPE, URL } from "@/helpers/constants";
import LikeRadioButtons from "@/components/mylogy/LikeRadioButtons";
import SearchbaseDrugResultItem from "@/components/mylogy/pgx_results/SearchbaseDrugResultItem";
import SearchbaseGeneResultItem from "@/components/mylogy/pgx_results/SearchbaseGeneResultItem";
import FondoCustom from "@/components/mylogy/FondoCustom.vue";
import DrugCategoriesFilter from "@/components/mylogy/DrugCategoriesFilter.vue";
import { mapGetters } from "vuex";
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";

export default {
  components: {
    LikeRadioButtons,
    SearchbaseDrugResultItem,
    SearchbaseGeneResultItem,
    FondoCustom,
    PageTopTitle,
    DrugCategoriesFilter
  },
  data() {
    return {
      app: "kb-drugs",
      categoryValue: "",
      categoryValueOriginal: "",
      searchBaseKey: 1,
      includeWithoutPGX: false,
      credentials: CRED,
      type: TYPE,
      url: URL,
      search: null,
      geneResults: {},
      panel: [0]
    };
  },
  watch: {
    includeWithoutPGX: function() {
      this.searchBaseKey++;
    }
  },
  computed: {
    ...mapGetters("pgxDrugResults", ["pgxDrugResult"]),
    ...mapGetters("gxGeneResults", ["gxGeneResult"]),
    ...mapGetters("drugCategories", ["drugCategoriesDict"]),
    sortOptions() {
      if (this.app == "kb-drugs") {
        return [
          {
            sortBy: "asc",
            dataField: `Drug_Name_${this.$i18n.locale.toUpperCase()}.keyword`,
            label: "ID"
          }
        ];
      } else {
        return [{ sortBy: "asc", dataField: "_id", label: "ID" }];
      }
    },
    searchPlaceholder() {
      if (this.app == "kb-drugs") {
        return this.$t("buscar medicamentos");
      } else {
        return this.$t("buscar genes");
      }
    },
    reactiveListReact() {
      if (this.app == "kb-drugs") {
        return {
          and: [
            "SearchSensor",
            "DrugSearchDisesasesSensor",
            "DrugSearchGenesSensor",
            "CategorySensor"
          ]
        };
      } else {
        return {
          and: ["SearchSensor", "PanelSensor", "GeneSearchDisesasesSensor"]
        };
      }
    },
    drugGeneDatafields() {
      return ["Substrate_of", "Induces", "Inhibits", "Related"];
    },
    datafields() {
      if (this.app == "kb-drugs") {
        return [`Drug_Name_${this.$i18n.locale.toUpperCase()}`, "Brandnames"];
      } else {
        return [
          "Alternative_Names",
          "Alternative_Symbols",
          "Symbol",
          `Gene_Name_${this.$i18n.locale.toUpperCase()}`,
          "OMIM"
        ];
      }
    }
  },
  mounted() {},
  methods: {
    catCustomQuery: (value, props) => {
      return {
        query: {
          wildcard: {
            EPG_drug_categories_string: {
              value: `*${value}*`
            }
          }
        }
      };
    },
    clearFilter(componentId, callback) {
      callback(componentId, null);
      if (componentId == "CategorySensor") {
        this.categoryValue = "";
        this.categoryValueOriginal = "";
      }
    },
    getFilteredValues(values = {}) {
      let self = this;
      const filteredValues = {};
      Object.keys(values).forEach(componentId => {
        if (
          values[componentId].showFilter &&
          (Array.isArray(values[componentId].value)
            ? values[componentId].value.length
            : !!values[componentId].value)
        ) {
          filteredValues[componentId] = values[componentId];
          if (componentId == "CategorySensor") {
            const catId = filteredValues[componentId].value
              .replace("root", "")
              .replace(/o/g, ".")
              .slice(0, -1);
            filteredValues[componentId].showValue =
              self.drugCategoriesDict[catId][self.$i18n.locale];
          }
        }
      });
      return filteredValues;
    },
    selectDrugCategory(cat) {
      if (cat != "") {
        this.categoryValueOriginal = cat;
        this.categoryValue =
          "root" + cat.replace(/\./g, "o") + ((cat != "" && "o") || "");
      } else {
        this.categoryValueOriginal = "";
        this.categoryValue = "";
      }
    },
    drug(s) {
      return {
        ...{ source: s },
        ...{ drugResult: this.pgxDrugResult(s.Slug) }
      };
    },
    gene(s) {
      return {
        ...{ source: s },
        ...{ geneResult: this.gxGeneResult(s.Symbol) }
      };
    },
    defaultQuery() {
      let self = this;
      if (this.app == "kb-drugs") {
        if (!this.includeWithoutPGX) {
          return {
            query: {
              bool: {
                must: {
                  match: { Has_PGX: true }
                },
                must_not: {
                  match: { Hide_In_PGX: true }
                }
              }
            }
          };
        } else {
          return {
            query: {
              bool: {
                must_not: {
                  match: { Hide_In_PGX: true }
                }
              }
            }
          };
        }
      } else {
        return {
          query: {
            match: { Has_Card: true }
          }
        };
      }
    },
    buttonSelected(button) {
      this.app = button.key;
      this.searchBaseKey++;
    }
  }
};
</script>

<style lang="scss">
#SearchSensor-input {
  background-image: url("~@/assets/img/search-icon-white.png") !important;
  background-size: 25px !important;
  background-repeat: no-repeat !important;
  background-position-y: center !important;
  background-position-x: 10px !important;
  padding-left: 50px !important;
}
.search-bar .buttons button {
  flex-grow: 1;
  margin-right: 10px !important;
}

.notdefined-switch {
  label {
    color: #777  !important;
  }
  .v-messages {
    display: none;
  }
}

.searchbase-results {
  width: 100%;
  background: transparent;
}

/* Paginacion pagination */
.results:nth-child(3) {
  a {
    background-color: transparent !important;
    color: #4d2cb9;
  }
  :focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

#selected-filters .v-btn__content {
  padding-top: 0 !important;
}

.drug-filters, .genes-filter {
  ul {
    padding-left: 0;
  }
}
.legend-disclaimer {
  font-size: x-small;
  text-align: justify;
  font-weight: 600;
  color: red;
}
</style>

<style lang="scss">
.css-1xl6sbp * {
  font-size: none;
}
</style>
